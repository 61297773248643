import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  fadeInUp,
  staggerReveal,
  staggerRevealClose,
  staggerText,
  handleTech,
  handleTechReturn,
  handleHover,
  handleHoverExit,
} from "../animations/animations";

import html from "../images/html.jpg";
import css from "../images/css.jpg";
import javascript from "../images/javascript.jpg";
import nextjs from "../images/nextjs.jpg";
import reactjs from "../images/reactjs.jpg";
import ts from "../images/ts.jpg";

const techs = [
  { name: "HTML", image: html },
  { name: "CSS", image: css },
  { name: "JavaScript", image: javascript },
  { name: "TypeScript", image: ts },
  { name: "ReatcJS", image: reactjs },
  { name: "NextJS", image: nextjs },
  { name: "NodeJS", image: javascript },
];

const Hamburger = ({ state }) => {
  const menuLayer = useRef(null);
  const reveal1 = useRef(null);
  const reveal2 = useRef(null);
  const cityBackground = useRef(null);
  const line1 = useRef(null);
  const line2 = useRef(null);
  const line3 = useRef(null);
  const info = useRef(null);

  useEffect(() => {
    if (state.clicked === false) {
      staggerRevealClose([reveal2.current, reveal1.current]);

      gsap.to(menuLayer.current, { duration: 1, css: { display: "none" } });
    } else if (
      state.clicked === true ||
      (state.clicked === true && state.initial === null)
    ) {
      gsap.to(menuLayer.current, { duration: 0, css: { display: "block" } });

      gsap.to([reveal1.current, reveal2.current], {
        duration: 0,
        opacity: 1,
        height: "100%",
      });

      staggerReveal(reveal1.current, reveal2.current);
      fadeInUp(info.current);
      staggerText(line1.current, line2.current, line3.current);
    }
  }, [state]);

  return (
    <div ref={menuLayer} className="hamburger-menu">
      <div ref={reveal1} className="menu-secundary-background-color"></div>
      <div ref={reveal2} className="menu-layer">
        <div ref={cityBackground} className="menu-city-background"></div>
        <div className="container">
          <div className="wrapper">
            <div className="menu-links">
              <nav>
                <ul>
                  <li>
                    <Link
                      onMouseEnter={(e) => handleHover(e)}
                      onMouseOut={(e) => handleHoverExit(e)}
                      ref={line1}
                      to="/quem-sou-eu"
                    >
                      Quem sou eu?
                    </Link>
                  </li>
                  <li>
                    <Link
                      onMouseEnter={(e) => handleHover(e)}
                      onMouseOut={(e) => handleHoverExit(e)}
                      ref={line2}
                      to="/projetos"
                    >
                      Projetos
                    </Link>
                  </li>
                  <li>
                    <Link
                      onMouseEnter={(e) => handleHover(e)}
                      onMouseOut={(e) => handleHoverExit(e)}
                      ref={line3}
                      to="/entre-em-contato"
                    >
                      Fale comigo
                    </Link>
                  </li>
                </ul>
              </nav>
              <div ref={info} className="info">
                <h3>Oi!</h3>
                <p>
                  Meu nome é <b>Alan Gabriel</b>, mineiro contador de historia,
                  sou dev frontend, atuando há 3 anos no mercado com ReactJS.
                </p>
              </div>
              <div className="locations">
                Tech atual:
                {techs.map((tech) => (
                  <span
                    onMouseEnter={() =>
                      handleTech(tech.image, cityBackground.current)
                    }
                    onMouseOut={() => handleTechReturn(cityBackground.current)}
                    key={tech.name}
                  >
                    {tech.name}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hamburger;
